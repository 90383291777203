import axios from 'axios';
import JSZip from 'jszip';
import {save} from 'save-file';

export default {

  data() {
    return {

      downloading: false,
    };
  },
  methods: {

    download({data, headers}) {
      const dispositions = headers['content-disposition'].split(';').map(str => ({

        key: str.split('=')[0].trim(),
        value: str.split('=')[1],
      }));

      const filename = dispositions.find(kvp => kvp.key === 'filename').value;

      save(data, filename);
    },

    downloadRecipeCards(data) {
      try {
        this.downloading = true;
        const zip = new JSZip();
        const pdfLinks = data.recipes.map(async item => {
          const file = await axios.get(`${process.env.VUE_APP_API}recipes/${item.id}/download${data?.status !== 'draft' ? '?weekly_menu=' + data.id : ''}`, {responseType: 'arraybuffer'});

          return {
            'file': new Blob([file.data], {type: 'application/pdf'}),
            item,
          };
        });

        Promise.all(pdfLinks).then(pdfList => {
          pdfList.forEach(pdf => zip.file(`R${pdf.item.number}-${pdf.item.name}.pdf`, pdf.file));

          zip.generateAsync({type: 'blob'})
            .then(content => {
              save(content, `Recipe Cards ${this.weekName(data.startDate)}.zip`);

              this.downloading = false;
            });
        });
      }
      catch (err) {
        // eslint-disable-next-line
        console.error(err);
      }
    },
  },
};
